import {
  Button,
  FacebookColorIcon,
  FacebookIcon,
  Flex,
  GoogleColorIcon,
  GoogleIcon,
  Separator,
  Text,
} from '@iheartradio/web.companion';
import { useSearchParams, useSubmit } from '@remix-run/react';
import type React from 'react';
import { useEffect, useMemo } from 'react';

import { useConfig } from '~app/contexts/config';
import { facebookSdkFactory, googleSdkFactory } from '~app/utilities/social';
import { type OauthLoginActionBody } from '~app/utilities/social/types';

type SocialLoginParams = {
  disabled?: boolean;
  setProvider: React.Dispatch<
    React.SetStateAction<'Facebook' | 'Google' | null>
  >;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  sendLoginMessage: () => void;
};

const buildFormData = (
  data: OauthLoginActionBody,
  skipAccountCheck: boolean,
  redirectUrl: string | null,
): FormData => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, String(value));
  }
  if (skipAccountCheck) formData.append('skipCheck', 'true');
  if (redirectUrl) formData.append('redirectUrl', redirectUrl);

  return formData;
};

export const SocialLogin = ({
  disabled = false,
  setProvider,
  setShowNotification,
  sendLoginMessage,
}: SocialLoginParams) => {
  const config = useConfig();
  const submit = useSubmit();
  const [searchParams] = useSearchParams();
  const skipAccountCheck = searchParams.get('skipAccountCheck') === 'true';
  const redirectUrl = searchParams.get('redirectUrl');

  const googleSdk = useMemo(
    () =>
      googleSdkFactory({
        onLogin: postBody => {
          sendLoginMessage();
          submit(buildFormData(postBody, skipAccountCheck, redirectUrl), {
            action: '/social/login?index',
            method: 'post',
          });
        },
        onError: () => {
          setProvider('Google');
          setShowNotification(true);
        },
      }),
    [
      submit,
      skipAccountCheck,
      setProvider,
      setShowNotification,
      redirectUrl,
      sendLoginMessage,
    ],
  );

  const facebookSdk = useMemo(
    () =>
      facebookSdkFactory({
        onLogin: postBody => {
          sendLoginMessage();
          submit(buildFormData(postBody, skipAccountCheck, redirectUrl), {
            action: '/social/login?index',
            method: 'post',
          });
        },
        onError: () => {
          setProvider('Facebook');
          setShowNotification(true);
        },
      }),
    [
      submit,
      skipAccountCheck,
      setProvider,
      setShowNotification,
      redirectUrl,
      sendLoginMessage,
    ],
  );

  // Initialize facebookSdk and googleSdk on mount, and remove them on un-mount
  useEffect(() => {
    if (!facebookSdk.initialized && config.sdks.facebook?.appId) {
      facebookSdk.initialize(config.sdks.facebook?.appId);
    }
    if (!googleSdk.initialized && config.sdks.googlePlus?.appKey) {
      googleSdk.initialize(config.sdks.googlePlus?.appKey);
    }

    return () => {
      googleSdk.remove();
      facebookSdk.remove();
    };
  }, [
    config.sdks.facebook?.appId,
    config.sdks.googlePlus?.appKey,
    facebookSdk,
    googleSdk,
  ]);

  if (!(config.sdks.facebook?.appId || config.sdks.googlePlus?.appKey))
    return null;

  return (
    <Flex
      direction="column"
      gap="$16"
      padding="$16 $24 $24 $24"
      textAlign="center"
    >
      <Flex alignItems="center" direction="row" gap="$16">
        <Separator />
        <Text kind="subtitle-3">OR</Text>
        <Separator />
      </Flex>
      <Button
        aria-label="Continue with Facebook"
        color={{ dark: 'white', light: 'gray' }}
        data-test="fb-button"
        disabled={disabled}
        inline
        kind="secondary"
        onClick={facebookSdk.login}
        size="large"
      >
        <Flex alignItems="center" direction="row" gap="$8">
          {disabled ? <FacebookIcon /> : <FacebookColorIcon />}
          Continue with Facebook
        </Flex>
      </Button>
      <Button
        aria-label="Continue with Google"
        color={{ dark: 'white', light: 'gray' }}
        data-test="google-button"
        disabled={disabled}
        inline
        kind="secondary"
        onClick={googleSdk.login}
        size="large"
      >
        <Flex alignItems="center" direction="row" gap="$8">
          {disabled ? <GoogleIcon /> : <GoogleColorIcon />}
          Continue with Google
        </Flex>
      </Button>
    </Flex>
  );
};
